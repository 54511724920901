import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  QUICK_ADJUSTMENT_ACTIONS,
  QUICK_ADJUSTMENT_TRIGGERS,
} from 'v2/common/constants/index';
import ModalComponent from 'v2/common/components/ModalComponent';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { quickAdjustmentPropTypes } from 'common/propTypes';
import { useQuickAdjustments } from 'v2/modules/withRunForm/hooks/useQuickAdjustments/index';
import QuickAdjustmentsForm, { BLANK_QUICK_ADJUSTMENT } from './QuickAdjustmentForm';

const propTypes = {
  intentId: PropTypes.string.isRequired,
  quickAdjustments: PropTypes.arrayOf(quickAdjustmentPropTypes),
  onUpdate: PropTypes.func.isRequired,
};

const defaultProps = {
  quickAdjustments: [],
};

const renderQuickAdjustmentText = (quickAdjustment, index) => {
  const { trigger, action, targets } = quickAdjustment;
  const triggerItem = _.find(QUICK_ADJUSTMENT_TRIGGERS, { value: trigger });
  const actionItem = _.find(QUICK_ADJUSTMENT_ACTIONS, { value: action });

  const badgeClassNames = classNames(
    'quick-adjustment-badge badge badge-light align-middle'
  );
  const triggerClassNames = classNames('trigger', {
    'text-success': triggerItem === QUICK_ADJUSTMENT_TRIGGERS.ExitedInProfit,
    'text-danger': triggerItem === QUICK_ADJUSTMENT_TRIGGERS.ExitedInLoss,
  });
  const targetLabels = targets.map((target) => `${target}`).join(' | ');
  return (
    <div className={badgeClassNames} key={index}>
      <span className={triggerClassNames}>
        On&nbsp;
        {triggerItem?.shortLabel}
        ,
      </span>
      <span className="action">{actionItem?.shortLabel}</span>
      <span className="targets">
        Leg&nbsp;
        {targetLabels}
      </span>
    </div>
  );
};

const renderQuickAdjustmentsText = (quickAdjustments) => {
  if (!quickAdjustments || quickAdjustments.length === 0) {
    return (
      <button
        className="btn btn-link btn-block btn-sm add-trigger-btn track"
        type="button"
      >
        <span className="material-icons-outlined tx-22 mr-1">add</span>
        Quick Adjustments
      </button>
    );
  }

  return [
    <button type="button" className="edit-btn" key={-1}>
      Edit
    </button>
  ].concat(
    quickAdjustments.map(renderQuickAdjustmentText)
  );
};

const QuickAdjustments = ({ intentId, quickAdjustments, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const { fieldId, error } = useQuickAdjustments(intentId, quickAdjustments);
  const [localQuickAdjustments, setLocalQuickAdjustments] = useState(quickAdjustments);

  useEffect(
    () => setLocalQuickAdjustments(quickAdjustments),
    [quickAdjustments]
  );

  useEffect(() => {
    if (showModal && _.isEmpty(quickAdjustments)) {
      setLocalQuickAdjustments([BLANK_QUICK_ADJUSTMENT]);
    }
  }, [quickAdjustments, setLocalQuickAdjustments, showModal]);

  const onModalSave = useCallback(() => {
    setShowModal(false);
    onUpdate(localQuickAdjustments);
  }, [localQuickAdjustments, onUpdate]);

  const shouldConfirmClose = useCallback(() => {
    return !_.chain(localQuickAdjustments)
      .filter(BLANK_QUICK_ADJUSTMENT)
      .isEqual(quickAdjustments)
      .value();
  }, [quickAdjustments, localQuickAdjustments]);

  const textContainerClassNames = classNames('text-container', `total-items-${quickAdjustments.length}`);
  return (
    <div className="transaction_details-items quick-adjustment" id={fieldId}>
      <div className={textContainerClassNames} onClick={() => setShowModal(!showModal)}>
        <ErrorMesssage message={error.message} isPremiumError={error.isPremiumError}>
          {renderQuickAdjustmentsText(quickAdjustments)}
        </ErrorMesssage>
      </div>
      <ModalComponent
        title={`Leg ${intentId} Quick Adjustments`}
        size="xl"
        shouldShow={showModal}
        shouldConfirmClose={shouldConfirmClose}
        onSave={onModalSave}
        onClose={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <QuickAdjustmentsForm
          intentId={intentId}
          quickAdjustments={localQuickAdjustments}
          onUpdate={setLocalQuickAdjustments}
        />
      </ModalComponent>
    </div>
  );
};

QuickAdjustments.propTypes = propTypes;
QuickAdjustments.defaultProps = defaultProps;

export default QuickAdjustments;
