import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { runPropTypes, userPropTypes } from 'common/propTypes';
import { RUN_TYPES, ORDER_TYPES } from 'v2/common/constants/index';

import { RunConfigSanitizer } from 'v2/modules/withRunForm/sanitizer/index';
import Main from './Main';

const propTypes = {
  run: runPropTypes.isRequired,
  currentUserDefaultFolderId: PropTypes.number,
  isRunBelongsToUser: PropTypes.bool.isRequired,
  currentUser: userPropTypes,
  shouldRenderDeployBtn: PropTypes.bool.isRequired,
};

const defaultProps = { currentUserDefaultFolderId: null, currentUser: null };

const DeployLiveRun = ({
  run, currentUser, currentUserDefaultFolderId, isRunBelongsToUser, shouldRenderDeployBtn
}) => {
  if (!shouldRenderDeployBtn) return null;

  const quantConfig = _.get(run, 'quant_config', {});
  const runType = _.get(run, 'run_type');
  const isHiddenSignal = _.get(run, 'isHiddenSignal');
  const hiddenByUserId = _.get(run, 'hidden_by_user_id');
  if (_.isEmpty(quantConfig)) return null; // for hided quant_config

  if (runType === RUN_TYPES.live) return null;

  const goLiveTitle = 'Deploy Strategy in Live';

  const [shouldShowModal, setShouldShowModal] = useState(false);

  const config = {
    orderType: ORDER_TYPES.paper.value,
    runType: RUN_TYPES.live,
    quantConfig,
    currentUser,
    run,
    currentUserDefaultFolderId,
    shouldShowModal,
    onCloseModal: () => setShouldShowModal(false),
    isRunBelongsToUser,
    granularity: run.granularity,
    startDate: run.start_date,
    selectedFolder: null,
    folderId: isRunBelongsToUser ? _.get(run, 'folder_id') : currentUserDefaultFolderId,
    endDate: run.end_date,
    strategyId: run.strategy_id,
    runName: run.name,
    engineVersion: 2,
    isEditQuant: false,
  };

  if (isHiddenSignal) {
    config.hiddenByUserId = hiddenByUserId;
    config.isHiddenSignal = isHiddenSignal;
  }

  const sanitizedRunConfig = RunConfigSanitizer.onLoad(config);

  return (
    <div>
      <button
        className="btn btn-sm btn-primary text-nowrap track"
        onClick={() => setShouldShowModal(true)}
        type="button"
        data-track-category="Deploy Live In Show Page"
        data-track-action="deploylive"
        data-track-label="Show Page"
        id="Deploy Live Modal"
        name="Deploy Live Modal"
      >
        {goLiveTitle}
      </button>
      {shouldShowModal && <Main {...sanitizedRunConfig} />}
    </div>
  );
};

DeployLiveRun.propTypes = propTypes;
DeployLiveRun.defaultProps = defaultProps;

export default DeployLiveRun;
